import React from 'react'
import PropTypes from 'prop-types'

import { content } from './LegalContent.module.scss'

const LegalContent = ({ html }) => {
  return <div className={content} dangerouslySetInnerHTML={{ __html: html }} />
}

LegalContent.propTypes = {
  html: PropTypes.string,
}

LegalContent.defaultProps = {
  html: '',
}

export default LegalContent
